.carousel__img{
    height: 63vh;
}
.small__hero__img{
    height: 45vh;
}
.page{
    min-height: 36vh;
}
@media screen and (max-width: 768px) {
    .carousel__img{
        height: 54vh;
    }
}
.active__link{
    border-left: 3.6px solid #e0a82e;
    padding-left: 0.36em;
}
.full_img{
    width: max-content;
}
.comma__list__item + .comma__list__item::before {
    display: inline-block;
    white-space: pre;
    content: ", ";
}
.mobile__filter{
    z-index: 30;
    bottom: 27px;
}
.mobile__filter__box{
    height: 54vh; 
    overflow-y: auto;
}
.filter__btn{
    position: fixed;
    z-index: 20;
    bottom: 27px;
    right: 18px;
}
.suggestions-list {
    border: 1px solid #ccc;
    color: white;
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
    max-height: 200px;
    overflow-y: auto;
}
.suggestion-item {
    padding: 7px;
    cursor: pointer;
}
.suggestion-item:hover {
    background-color: #f0f0f0;
    color: black;
}
:root{
	color-scheme: inherit;
}